import React from "react";
import "./partySection.css";
import SongList from "../components/SongList";
import ScoreboardTile from "../components/ScoreboardTile";

const PartySection: React.FC = () => {
  return (
    <div className="grid-container workspace-grid">
      <div className="text-container">
        <div className="large">THROW YOUR OWN PARTY</div>
        <div className="regular">
          Invite friends and family to vote, and let the dramatic results unfold
          in your very own private{" "}
          <div className="label-text">
            <svg
              width=".8rem"
              height=".8rem"
              viewBox="0 0 24 24"
              fill="#090909"
            >
              <path d="M18 8.31c-.36-.41-.73-.82-1.12-1.21l-.29-.27.14-.12a3.15 3.15 0 00.9-3.49A3.91 3.91 0 0014 1v2a2 2 0 011.76 1c.17.4 0 .84-.47 1.31-.07.08-.15.13-.22.2-3-2.41-6.29-3.77-7.9-2.16a2.16 2.16 0 00-.41.59v.1l-.18.53-4.41 13.1A3.28 3.28 0 005.28 22a3.21 3.21 0 001-.17L20 17.28a1 1 0 00.43-.31l.21-.18c1.43-1.44.51-4.21-1.41-6.9A6.63 6.63 0 0123 9V7a8.44 8.44 0 00-5 1.31zM5.7 19.93a1.29 1.29 0 01-1.63-1.63l1.36-4.1a10.7 10.7 0 004.29 4.39zm7-2.33a8.87 8.87 0 01-6.3-6.29l1-3 .06.09c.11.22.25.45.39.68s.16.29.26.44.33.48.51.73.19.28.3.42.43.55.66.82l.29.35c.34.39.7.77 1.08 1.16s.68.64 1 1l.33.28.78.63.37.28c.28.2.55.4.83.58l.31.2c.36.22.72.43 1.07.61h.05zm6.51-2.23h-.06c-.69.38-3.56-.57-6.79-3.81-.34-.34-.66-.67-.95-1l-.29-.35-.53-.64-.29-.4c-.13-.19-.27-.37-.39-.55l-.26-.42-.29-.47c-.08-.14-.14-.27-.21-.4s-.15-.26-.21-.4a3.31 3.31 0 01-.14-.36c-.05-.13-.11-.26-.15-.38S8.6 6 8.57 5.88s-.05-.22-.07-.32a2.26 2.26 0 010-.26 1 1 0 010-.24l.11-.31c.36-.36 2.23 0 4.73 1.9A4.13 4.13 0 0112 7v2a6.45 6.45 0 003-.94l.48.46c.42.42.81.85 1.18 1.28a5.32 5.32 0 00-.6 3.4l2-.39a3.57 3.57 0 010-1.12 11.3 11.3 0 01.81 1.45c.56 1.32.52 2.06.34 2.23z" />
            </svg>
            SCOREBOARD PARTY
          </div>
        </div>
      </div>
      <div className="party-workspace">
        <SongList
          songs={[
            {
              countryCode: "fi",
              title: "Cha cha cha",
              sub: "Käärijä",
              points: 57,
            },
            {
              countryCode: "se",
              title: "Tattoo",
              sub: "Loreen",
              points: 52,
            },
            {
              countryCode: "il",
              title: "Unicorn",
              sub: "Noa Kirel",
              points: 36,
            },
          ]}
        />
        <div className="party-tile title">
          <ScoreboardTile
            text="eurovision 2023"
            color1="#00247d"
            color2="#767676"
            color3="#cf142b"
            aspectRatio={2.5}
            width="15rem"
            textStyle={{
              top: "1rem",
              right: "1rem",
              textAlign: "right",
              fontSize: "1.2rem",
            }}
          />
        </div>
        <div className="party-tile family">
          <ScoreboardTile
            text="family"
            subtext="YOU, MUM, AND 2 MORE"
            color1="#00247d"
            color2="#767676"
            color3="#cf142b"
            aspectRatio={2.5}
            width="15rem"
          />
        </div>
        <div className="party-tile fan-club">
          <ScoreboardTile
            text="fan club"
            subtext="YOU, LUKAS, AND 18 MORE"
            color1="#00247d"
            color2="#767676"
            color3="#cf142b"
            aspectRatio={2.5}
            width="15rem"
            newLine={false}
            textStyle={{
              bottom: "1rem",
              right: "1rem",
              textAlign: "right",
            }}
          />
        </div>
        <div className="party-tile friends">
          <ScoreboardTile
            text="friends"
            subtext="YOU, ANNA, AND 5 MORE"
            color1="#00247d"
            color2="#767676"
            color3="#cf142b"
            aspectRatio={2.5}
            width="15rem"
          />
        </div>
      </div>
    </div>
  );
};

export default PartySection;
