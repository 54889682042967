import React from "react";
import Flag from "../Flag";
import Points from "./Points";

interface Song {
  countryCode: string;
  title: string;
  sub: string;
  points?: number;
}

interface SongListProps {
  songs: Song[];
  color?: string;
  position?: boolean;
}

const SongList: React.FC<SongListProps> = ({ songs, color, position }) => {
  return (
    <div className="songs">
      {songs.map((song, index) => (
        <div
          className="song-item"
          key={index}
          style={{ backgroundColor: color }}
        >
          <div className="song-item-left">
            {position && <div className="song-position">{index + 1}</div>}
            <Flag country={song.countryCode} />
            <div>
              <div className="song-main">{song.title.toUpperCase()}</div>
              <div className="song-sub">{song.sub.toUpperCase()}</div>
            </div>
          </div>
          {song.points && <Points points={song.points} />}
        </div>
      ))}
    </div>
  );
};

export default SongList;
