import React from "react";
import "./logo.css";
const icon = require(`../Icon.svg`).default;

const EuropartyLogo: React.FC = () => {
  return (
    <div className="logo">
      <img src={icon} alt={`Europarty icon`} width={"15rem"} />
      <div>Europarty</div>
    </div>
  );
};

export default EuropartyLogo;
