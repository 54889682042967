import React from "react";

interface GradientProps {
  children: React.ReactNode;
  color1: string;
  color2: string;
  color3: string;
  width?: string;
  aspectRatio?: number;
  textStyle?: React.CSSProperties;
}

const darkenColor = (color: string, percent: number): string => {
  let r: number = parseInt(color.slice(1, 3), 16);
  let g: number = parseInt(color.slice(3, 5), 16);
  let b: number = parseInt(color.slice(5, 7), 16);

  r = Math.round(r * (1 - percent));
  g = Math.round(g * (1 - percent));
  b = Math.round(b * (1 - percent));

  return `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
};

const Tile: React.FC<GradientProps> = ({
  children,
  color1,
  color2,
  color3,
  width = "12rem",
  aspectRatio = 1.6,
  textStyle = { top: "0", left: "0" },
}) => {
  const darkenFactor = 0.4;
  const darkenedColor1 = darkenColor(color1, darkenFactor);
  const darkenedColor2 = darkenColor(color2, darkenFactor);
  const darkenedColor3 = darkenColor(color3, darkenFactor);

  return (
    <div
      style={{
        ...styles.parent,
        width: width,
        aspectRatio: aspectRatio,
        background: `linear-gradient(to bottom right, ${darkenedColor1}, ${darkenedColor2} 60%, ${darkenedColor3} 120%)`,
        overflow: "hidden",
      }}
    >
      <div className="noise"></div>

      <div style={{ ...styles.text, ...textStyle }}>{children}</div>
    </div>
  );
};

const styles = {
  parent: {
    borderRadius: "10px",
    boxSizing: "border-box",
  } as React.CSSProperties,
  gradientBackground: {
    width: "100%",
    height: "100%",
  },
  text: {
    fontWeight: "700",
    fontSize: ".95rem",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    zIndex: 11,
  } as React.CSSProperties,
  noise: {
    position: "absolute",
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  } as React.CSSProperties,
};

export default Tile;
