import { ChartContainer } from "@mui/x-charts/ChartContainer";
import {
  LinePlot,
  MarkPlot,
  lineElementClasses,
  markElementClasses,
} from "@mui/x-charts/LineChart";
import { useEffect, useState } from "react";

const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

const shuffleArray = (array: number[]): number[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1));
    [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
  }
  return array;
};

export default function TinyLineChart() {
  const [shuffledData, setShuffledData] = useState(pData);

  // Shuffle the data every 2 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setShuffledData(shuffleArray([...pData]));
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <ChartContainer
      margin={{ left: 10, right: 10, top: 10, bottom: 0 }}
      width={240}
      height={144}
      series={[{ type: "line", data: shuffledData }]}
      xAxis={[{ scaleType: "point", data: xLabels }]}
      sx={{
        [`& .${lineElementClasses.root}`]: {
          stroke: "#fff",
          strokeWidth: 2,
        },
        [`& .${markElementClasses.root}`]: {
          stroke: "#fff",
          scale: "1",
          fill: "#fff",
          strokeWidth: 1,
        },
      }}
      disableAxisListener
    >
      <LinePlot />
      <MarkPlot />
    </ChartContainer>
  );
}
