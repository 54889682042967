import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import "./markdown.css";

const Terms: React.FC = () => {
  const [text, setText] = useState("");
  useEffect(() => {
    const firstPath = require("./assets/terms.md");

    fetch(firstPath)
      .then((response) => {
        return response.text();
      })
      .then((text) => setText(text));
  }, []);

  return (
    <div className="markdown-content">
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};

export default Terms;
