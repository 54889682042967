import React, { useState, useEffect } from "react";
import ScoreboardTile from "./ScoreboardTile";
import Flag from "../Flag";
import "./CountryPool.css";
import Points from "./Points";

const countries = [
  {
    code: "gr",
    text: "KALISPERA EUROPE!",
    subtext: "8 POINTS GO TO...",
    color1: "#0000ff",
    color2: "#767676",
    color3: "#0000ff",
  },
  {
    code: "de",
    text: "GUTEN ABEND FROM BERLIN!",
    subtext: "THESE ARE OUR FIRST POINTS...",
    color1: "#000000",
    color2: "#DD0000",
    color3: "#FFCC00",
  },
  {
    code: "cz",
    text: "DOBRÝ VEČER EUROPE!",
    subtext: "THE VOTES FROM CZECHIA ARE...",
    color1: "#11457E",
    color2: "#767676",
    color3: "#D7141A",
  },
  {
    code: "ie",
    text: "DUBLIN IS CALLING",
    subtext: "HERE ARE OUR VIEWERS’ VOTES!",
    color1: "#009A44",
    color2: "#767676",
    color3: "#FF8200",
  },
  {
    code: "es",
    text: "BUENAS TARDES FROM MADRID!",
    subtext: "HERE ARE THE SPANISH VOTES...",
    color1: "#aa151b",
    color2: "#f1bf00",
    color3: "#aa151b",
  },
  {
    code: "gb",
    text: "LONDON IS CALLING",
    subtext: "AND OUR DOUZE POINTS GO TO....",
    color1: "#00247d",
    color2: "#767676",
    color3: "#cf142b",
  },
  {
    code: "fr",
    text: "bonsoir l'europe!",
    subtext: "OUR 10 POINTS GO TO....",
    color1: "#000091",
    color2: "#767676",
    color3: "#E1000F",
  },
];

const CountryPool: React.FC = () => {
  const [currentCountry, setCurrentCountry] = useState(0);
  const [isExiting, setIsExiting] = useState(false);
  const [points] = useState([12, 10, 8, 7, 6, 5, 4, 3, 2, 1]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsExiting(true);

      setTimeout(() => {
        setCurrentCountry((prevIndex) =>
          prevIndex < countries.length - 1 ? prevIndex + 1 : 0
        );
        setIsExiting(false);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          className={`country-container ${isExiting ? "exiting" : ""}`}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Flag country={countries[currentCountry].code} />
          <ScoreboardTile
            text={countries[currentCountry].text}
            subtext={countries[currentCountry].subtext}
            color1={countries[currentCountry].color1}
            color2={countries[currentCountry].color2}
            color3={countries[currentCountry].color3}
            aspectRatio={4}
            width="18rem"
            newLine={false}
            textStyle={{
              textAlign: "center",
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", padding: "1rem" }}>
        {points.map((point, index) => (
          <div
            className={`points-container ${isExiting ? "exiting" : ""}`}
            style={{ animationDelay: `${index * 0.1}s` }}
            key={`points-${index}`}
          >
            <Points points={point} />
          </div>
        ))}
      </div>
    </>
  );
};

export default CountryPool;
