import React from "react";
import "./statsSection.css";
import Tile from "../components/Tile";
import SongList from "../components/SongList";
import TinyLineChart from "../components/TinyLineChart";

const StatsSection: React.FC = () => {
  return (
    <div className="grid-container workspace-grid">
      <div className="party-workspace">
        <div className="stats-tile top-10">
          <Tile
            color1="#4B1143"
            color2="#4B3611"
            color3="#B95F5F"
            width="20rem"
            aspectRatio={0.8}
          >
            <div className="stats-tile-title">MY TOP 10 COUNTRIES</div>
            <div className="fade-out">
              <SongList
                color="transparent"
                position
                songs={[
                  {
                    countryCode: "si",
                    title: "Slovenia",
                    sub: "🤍 CARPE DIEM",
                  },
                  {
                    countryCode: "gb",
                    title: "United Kingdom",
                    sub: "🤍 Save your kisses for me",
                  },
                  {
                    countryCode: "ua",
                    title: "Ukraine",
                    sub: "🤍 Sweet People",
                  },
                  {
                    countryCode: "nl",
                    title: "The Netherlands",
                    sub: "🤍 Arcade",
                  },
                  {
                    countryCode: "ee",
                    title: "Estonia",
                    sub: "🤍 La Forza",
                  },
                  {
                    countryCode: "cy",
                    title: "Cyprus",
                    sub: "🤍 Fuego",
                  },
                ]}
              />
            </div>
          </Tile>
        </div>
        <div className="stats-tile portugal">
          <Tile
            color1="#ff0000"
            color2="#767676"
            color3="#006600"
            width="17rem"
            aspectRatio={1.4}
          >
            <div>PORTUGAL TRENDS</div>
            <TinyLineChart />
          </Tile>
        </div>
        <div className="stats-tile poland">
          <Tile
            color1="#DC143C"
            color2="#767676"
            color3="#DC143C"
            width="17rem"
            aspectRatio={1.4}
          >
            <div>MY TRENDS OF POLAND</div>
            <TinyLineChart />
          </Tile>
        </div>
      </div>
      <div className="text-container">
        <div className="large">EUROVISION EUPHORIA FOR SUPERFANS</div>
        <div className="regular">
          Indulge in nerdy{" "}
          <div className="label-text">
            <svg
              width=".8rem"
              height=".8rem"
              viewBox="0 0 512 512"
              fill="#090909"
            >
              <path d="M104 496H72a24 24 0 01-24-24V328a24 24 0 0124-24h32a24 24 0 0124 24v144a24 24 0 01-24 24zM328 496h-32a24 24 0 01-24-24V232a24 24 0 0124-24h32a24 24 0 0124 24v240a24 24 0 01-24 24zM440 496h-32a24 24 0 01-24-24V120a24 24 0 0124-24h32a24 24 0 0124 24v352a24 24 0 01-24 24zM216 496h-32a24 24 0 01-24-24V40a24 24 0 0124-24h32a24 24 0 0124 24v432a24 24 0 01-24 24z" />
            </svg>
            STATISTICS
          </div>{" "}
          - See your top 10 countries and trends in your ranking, get detailed
          voting data and enjoy exclusive content
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
