import React from "react";
import SongList from "../components/SongList";
import "./heroSection.css";
import DownloadButton from "../components/downloadButton";

const HeroSection: React.FC = () => {
  return (
    <div className="hero-container">
      <div>
        <div className="large">
          RANK YOUR LOVED
          <br />
          EUROVISION SONGS
        </div>
        <div className="regular">
          Share your top songs with
          <br />
          friends, family and the world,
          <br />
          and see theirs
        </div>
        <DownloadButton />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SongList
          songs={[
            {
              countryCode: "se",
              title: "Sweden 1974",
              sub: "Abba - Waterloo",
              points: 12,
            },
            {
              countryCode: "no",
              title: "Norway 2009",
              sub: "Alexander Rybak - Fairytale",
              points: 10,
            },
            {
              countryCode: "ch",
              title: "Switzerland 2024",
              sub: "Nemo - The Code",
              points: 8,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default HeroSection;
