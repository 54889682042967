import React from "react";
import "./downloadButton.css";

const DownloadButton: React.FC<{ minimal?: boolean }> = ({
  minimal = false,
}) => {
  const getAppLink = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/android/i.test(userAgent)) {
      //return "https://play.google.com/store/apps/details?id=com.example";
      return "https://www.instagram.com/europartyapp/";
    } else if (/iphone|ipod|ipad|mac/i.test(userAgent)) {
      return "https://apps.apple.com/us/app/europarty/id6740097394";
    }
    return "https://www.instagram.com/europartyapp/";
  };

  const minimalButton = (
    <button
      onClick={() => (window.location.href = getAppLink())}
      style={{
        border: "1px white solid",
        borderRadius: 5,
        fontWeight: "500",
        padding: "5px 10px",
        background: "none",
        cursor: "pointer",
        boxSizing: "border-box",
      }}
    >
      Download
    </button>
  );

  return minimal ? (
    minimalButton
  ) : (
    <button
      className="download-button"
      onClick={() => (window.location.href = getAppLink())}
    >
      Download the app now
      <svg fill="none" viewBox="0 0 24 24" height="1.5em" width="1.5em">
        <path
          fill="#191919"
          d="M11 5a1 1 0 112 0v7.158l3.243-3.243 1.414 1.414L12 15.986 6.343 10.33l1.414-1.414L11 12.158V5z"
        />
        <path
          fill="#191919"
          d="M4 14h2v4h12v-4h2v4a2 2 0 01-2 2H6a2 2 0 01-2-2v-4z"
        />
      </svg>
    </button>
  );
};

export default DownloadButton;
