import React from "react";

interface GradientProps {
  text: string;
  subtext?: string;
  color1: string;
  color2: string;
  color3: string;
  width?: string;
  aspectRatio?: number;
  textStyle?: React.CSSProperties;
  newLine?: boolean;
}

const darkenColor = (color: string, percent: number): string => {
  let r: number = parseInt(color.slice(1, 3), 16);
  let g: number = parseInt(color.slice(3, 5), 16);
  let b: number = parseInt(color.slice(5, 7), 16);

  r = Math.round(r * (1 - percent));
  g = Math.round(g * (1 - percent));
  b = Math.round(b * (1 - percent));

  return `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
};

const ScoreboardTile: React.FC<GradientProps> = ({
  text,
  subtext,
  color1,
  color2,
  color3,
  width = "12rem", // Default width
  aspectRatio = 1.6, // Default aspect ratio
  textStyle: textPosition = {
    bottom: "1rem",
    left: "1rem",
  },
  newLine = true,
}) => {
  const darkenFactor = 0.4;
  const darkenedColor1 = darkenColor(color1, darkenFactor);
  const darkenedColor2 = darkenColor(color2, darkenFactor);
  const darkenedColor3 = darkenColor(color3, darkenFactor);

  const formattedText = newLine ? text.trim().split(" ") : [text];

  return (
    <div
      style={{
        ...styles.parent,
        width: width,
        aspectRatio: aspectRatio,
      }}
    >
      <div
        style={{
          ...styles.gradientBackground,
          background: `linear-gradient(to bottom right, ${darkenedColor1}, ${darkenedColor2} 60%, ${darkenedColor3} 120%)`,
        }}
      ></div>
      <div className="noise" style={{ position: "absolute" }}></div>

      <div style={{ ...styles.text, ...textPosition }}>
        {formattedText.slice(0, formattedText.length - 1).join(" ")}
        <br />
        {formattedText[formattedText.length - 1]}

        {subtext ? (
          <>
            <br />{" "}
            <span style={{ fontWeight: 300, fontSize: ".7rem" }}>
              {subtext}
            </span>
          </>
        ) : null}
      </div>
    </div>
  );
};

const styles = {
  parent: {
    borderRadius: "10px",
    position: "relative",
    overflow: "hidden",
    boxSizing: "border-box",
    margin: ".5rem",
    justifyContent: "center",
    display: "flex",
  } as React.CSSProperties,
  gradientBackground: {
    width: "100%",
    height: "100%",
  },
  text: {
    position: "absolute",
    fontWeight: "700",
    fontSize: ".95rem",
  } as React.CSSProperties,
};

export default ScoreboardTile;
