import React from "react";
import Marquee from "react-fast-marquee";
import ScoreboardTile from "../components/ScoreboardTile";
import "./scoreboardSection.css";

interface Scoreboard {
  text: string;
  color1: string;
  color2: string;
  color3: string;
}

const scoreboards: Scoreboard[] = [
  {
    text: "Eurovision 2025",
    color1: "#FF0000",
    color2: "#767676",
    color3: "#FF0000",
  },
  {
    text: "All winners",
    color1: "#8B7500",
    color2: "#FFD700",
    color3: "#FFD700",
  },
  {
    text: "Sanremo 2025",
    color1: "#008C45",
    color2: "#767676",
    color3: "#CD212A",
  },
  {
    text: "Eurovision 2024",
    color1: "#006AA7",
    color2: "#fecc00",
    color3: "#006AA7",
  },
  {
    text: "Eurovision 2023",
    color1: "#00247d",
    color2: "#767676",
    color3: "#cf142b",
  },
  {
    text: "Eurovision 2018",
    color1: "#ff0000",
    color2: "#767676",
    color3: "#006600",
  },
  {
    text: "Benidorm Fest 2025",
    color1: "#aa151b",
    color2: "#f1bf00",
    color3: "#aa151b",
  },
  {
    text: "Runners-up",
    color1: "#5c5c5c",
    color2: "#dcdcdc",
    color3: "#a9a9a9",
  },
  {
    text: "Eesti Laul 2025",
    color1: "#0072ce",
    color2: "#767676",
    color3: "#000000",
  },
  {
    text: "Melodifestivalen 2025",
    color1: "#006AA7",
    color2: "#fecc00",
    color3: "#006AA7",
  },
  {
    text: "Eurovision 1999",
    color1: "#0000ff",
    color2: "#767676",
    color3: "#0000ff",
  },
  {
    text: "Eurovision 1988",
    color1: "#ff8000",
    color2: "#767676",
    color3: "#009b00",
  },
  {
    text: "Eurovision 1956",
    color1: "#FF0000",
    color2: "#767676",
    color3: "#FF0000",
  },
  {
    text: "Eurovision 1974",
    color1: "#00247d",
    color2: "#767676",
    color3: "#cf142b",
  },
  {
    text: "Festivali i Këngës 63",
    color1: "#e41e2a",
    color2: "#090909",
    color3: "#e41e2a",
  },
  {
    text: "Eurovision 1969",
    color1: "#aa151b",
    color2: "#f1bf00",
    color3: "#aa151b",
  },
  {
    text: "Eurovision 2007",
    color1: "#0000ff",
    color2: "#767676",
    color3: "#0000ff",
  },
  {
    text: "Supernova 2025",
    color1: "#9e3039",
    color2: "#767676",
    color3: "#9e3039",
  },
  {
    text: "Montesong 2025",
    color1: "#e30b17",
    color2: "#f9d800",
    color3: "#e30b17",
  },
  {
    text: "Eurovision 2022",
    color1: "#008C45",
    color2: "#767676",
    color3: "#CD212A",
  },
];

const half = Math.ceil(scoreboards.length / 2);
const leftList = scoreboards.slice(0, half);
const rightList = scoreboards.slice(half);

const ScoreboardSection: React.FC = () => {
  return (
    <div>
      <div className="marquee-container">
        <Marquee loop={0} autoFill className="scoreboard-list">
          {leftList.map((scoreboard, index) => (
            <ScoreboardTile
              key={index}
              text={scoreboard.text}
              color1={scoreboard.color1}
              color2={scoreboard.color2}
              color3={scoreboard.color3}
            />
          ))}
        </Marquee>
        <div className="text-container">
          <span className="large">CHOOSE FROM A VARIETY OF SCOREBOARDS</span>
          <div className="regular">
            <div className="label-text">
              <svg
                width=".8rem"
                height=".8rem"
                viewBox="0 0 1024 1024"
                fill="#090909"
              >
                <path d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z" />
              </svg>
              RANK
            </div>{" "}
            Basel 2025, past years, national selections, record-makers and many
            more
          </div>
        </div>
        <Marquee
          loop={0}
          autoFill
          className="scoreboard-list"
          direction="right"
        >
          {rightList.map((scoreboard, index) => (
            <ScoreboardTile
              key={index}
              text={scoreboard.text}
              color1={scoreboard.color1}
              color2={scoreboard.color2}
              color3={scoreboard.color3}
            />
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default ScoreboardSection;
