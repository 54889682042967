import React from "react";

interface SongListProps {
  points: number;
}

const Points: React.FC<SongListProps> = ({ points }) => {
  return (
    <div style={{ position: "relative", display: "flex" }}>
      <div className="points">{points}</div>
    </div>
  );
};

export default Points;
